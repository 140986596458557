
import { FormInstance, ElMessage } from "element-plus";
import { Download, Search, Expand, ArrowDown } from "@element-plus/icons-vue";
import { defineComponent, ref, reactive } from "vue";
import SbomDataService from "@/services/SbomDataService";
import ResponseData from "@/types/ResponseData";
import FormItem from "@/components/ProductFormItem";
// import sbomDownloadPop from "@/components/SbomDownloadPop.vue";
import ProductItemConfig from "@/types/ProductItemConfig";
import { CloseProductDrawer, ParseUriProductName } from "@/utils";
import { useRouter } from "vue-router";
import { mapMutations, mapGetters } from "vuex";

export default defineComponent({
  name: "ProductInformation",
  components: {
    FormItem,
    // sbomDownloadPop,
    // ArrowDown
  },
  setup() {
    const productFormRef = ref<FormInstance>();
    return {
      productFormRef,
    };
  },
  data() {
    return {
      isRouterAlive: true,
      downloadIcon: Download,
      Search: Search,
      Expand: Expand,
      useRouter: useRouter,

      productDrawer: false,
      ParseUriProductName: ParseUriProductName,

      productName: (window as any).SBOM_PRODUCT_NAME
        ? (window as any).SBOM_PRODUCT_NAME
        : ("" as string),
      productType: "",

      productTypeList: [],
      productConfigList: [] as ProductItemConfig[],

      productForm: reactive({
        data: {},
        rules: {},
      }),
      showQuery: true,
      showTxt: false,
    };
  },
  computed: {
    ...mapGetters(["getProductName", "getIsThirdPart"]),
  },
  methods: {
    getProductTypeList() {
      SbomDataService.queryProductType()
        .then((response: ResponseData) => {
          this.productTypeList = response.data;
          const community = this.getParams("community");
          if (community) {
            let productType = "";
            response.data.map((item) => {
              if (community.toLowerCase().includes(item.toLowerCase())) {
                productType = item;
              }
            });
            if (productType) {
              this.showQuery = true;
              this.handleProductTypeChange(productType);
            }
          }
        })
        .catch((e: Error) => {
          console.error("query product type list failed:", { e });
        });
    },

    async queryArtifactInfo(formElem: FormInstance | undefined) {
      if (!formElem) return;
      await formElem.validate((valid, fields) => {
        if (valid) {
          SbomDataService.queryProduct(this.productType, this.productForm.data)
            .then((response: ResponseData) => {
              this.productName = response.data.name;
              (window as any).SBOM_PRODUCT_NAME = response.data.name;
              this.setProductName(response.data.name);
              this.showQuery = false;
            })
            .catch((e: any) => {
              if (e.response && e.response.status == 500) {
                ElMessage({
                  message: e.response.data,
                  type: "error",
                });
              }
            });
        }
      });
    },
    reset() {
      this.productForm.data = {};
      this.setProductName("");
      this.resetProductForm();
    },

    handleProductTypeChange(productType: string) {
      if (productType === this.productType) return;
      this.productConfigList = [];
      this.productType = productType;
      this.productForm.data = {};
      SbomDataService.queryProductConfig(productType)
        .then((response: ResponseData) => {
          const { data } = response;
          if (data && data.label && data.name) {
            this.productConfigList = [data];
            this.formatFormData(data, 0);
            // this.productForm.data[data.name] = ''
            // this.productForm.rules[data.name] = [
            //   { required: true, message: '请选择 ' + data.label, trigger: 'change' }
            // ];
            this.$nextTick(() => {
              this.resetProductForm();
            });
            this.showTxt = false;
          } else {
            this.productConfigList = [];
            this.showTxt = true;
            (window as any).SBOM_PRODUCT_NAME = "noData";
            this.setProductName("noData");
          }
        })
        .catch((e: Error) => {
          console.error("query product config failed:", { e });
        });
    },

    resetProductForm() {
      if (this.productFormRef) {
        this.productFormRef.resetFields();
      }
    },

    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
    getParams(prop) {
      let val = "";
      const url = window.location.hash.split("?")[1];
      if (url) {
        const params = url.split("&");
        if (params && params.length > 1) {
          params.map((prams) => {
            if (prams.includes(prop)) {
              val = prams.split("=")[1];
            }
          });
        }
      }
      return val;
    },
    productFormChange(val, data, name, index) {
      if (index === 0) {
        this.productForm.data = {};
        this.productForm.data[name] = val;
      }
      if (val) {
        if (data[val] && data[val].name) {
          this.productConfigList.splice(index + 1, 1, data[val]);
          this.productConfigList = this.productConfigList.slice(0, index + 2);
        } else {
          this.productConfigList = this.productConfigList.slice(0, index + 1);
        }
      } else {
        this.productConfigList = this.productConfigList.slice(0, index + 1);
      }
      this.formatFormData(data[val], index);
    },
    formatFormData(data, index) {
      if (data && data.name) {
        const keys = Object.keys(data.valueToNextConfig);
        this.productForm.data[data.name] =
          keys.length && keys.length === 1 ? keys[0] : "";
        this.productForm.rules[data.name] = [
          {
            required: true,
            message: "请选择 " + data.label,
            trigger: "change",
          },
        ];
        if (data.valueToNextConfig) {
          if (keys.length && keys.length === 1) {
            const val = data.valueToNextConfig[keys[0]];
            // this.formatFormData(val)
            this.productFormChange(
              keys[0],
              data.valueToNextConfig,
              val.name,
              index + 1
            );
          }
        }
      }
    },
    ...mapMutations(["setProductName"]),
  },

  provide() {
    return {
      reload: this.reload,
    };
  },

  mounted() {
    this.getProductTypeList();
    this.productName = this.ParseUriProductName();
    if (this.productName) {
      this.setProductName(this.productName);
      this.showQuery = false;
    }
  },
});
