
import { Download, Search, Expand, ArrowDown, SwitchButton } from '@element-plus/icons-vue';
import { defineComponent } from "vue";
import { useRouter } from 'vue-router';
import ProductInformation from '@/components/productInformation.vue';
import { mapMutations, mapGetters} from 'vuex';
import SbomDataService from "@/services/SbomDataService";
import { ElMessage } from "element-plus";
import ResponseData from "@/types/ResponseData";
export default defineComponent({
  name: "App",
  components: {
    ProductInformation,
    ArrowDown,
    SwitchButton
  },
  data() {
    return {
      downloadIcon: Download,
      Search: Search,
      Expand: Expand,
      useRouter: useRouter,
      navList: [
        // { label: '风险看板', path: '/sbomDashboard', value: 'sbomDashboard', href: '' },
        { label: '软件成分查询', path: '/sbomPackages', value: 'sbomPackages', href: ''  },
        { label: '开源软件反向追溯链', path: '/sbomTraceChain', value: 'sbomTraceChain', href: ''  },
        // { label: '漏洞影响范围查询', path: '/sbomVulnerability', value: 'sbomVulnerability', href: ''  },
        // { label: '制品漏洞全景', path: '/sbomPanorama', value: 'sbomPanorama', href: ''  },
      ],
      activeName: this.$route.name || '',
      isShow: false,
      thirdPart: false,
      defaultAvartar: require('@/assets/images/userAvatar.png')
    }
  },
  computed:{
    ...mapGetters([
      'getIsThirdPart',
      'getProductName',
      'getUser'
    ])
  },
  methods: {
    goPath(nav: any) {
      this.$router.push({
        path: nav.path
      })
    },
    openNewTab(nav: any) {
      const { href } = this.$router.resolve({
        path: nav.path,
        query: {
          productName: this.getProductName
        }
      })
      window.open(href, '_blank')
    },
    getHref(nav: any, index) {
      return this.navList[index].href || `#${nav.path}`
    },
    handleContextmenu(nav, index, type) {
      this.navList[index].href = (type === 'self' || !this.getProductName)
        ? `#${nav.path}` 
          : `#${nav.path}?productName=${this.getProductName}`
    },
    logout() {
      SbomDataService.handleLogout()
        .then((response: ResponseData) => {
          this.setUser({})
          this.$router.push('/login')
        })
        .catch((e: any) => {
          if (e.response && e.response.status == 500) {
            ElMessage({
              message: e.response.data,
              type: "error",
            });
          }
        });
    },
    ...mapMutations(['setIsThirdPart', 'setUser'])

  },
  watch: {
    $route: {
      deep: true,
      handler(route) {
        this.isShow = !route.path.includes('sbomPackageDetail')
        if(this.isShow) {
          this.activeName = route.name || ''
        }
        // 判断是否第三方平台
        if(route.query) {
          const query = route.query
          if(query.thirdPart) {
            this.setIsThirdPart(query.thirdPart)
          }
        }
      }
    },
    getIsThirdPart() {
      this.thirdPart = this.getIsThirdPart
    }
  },
  mounted() {
    this.thirdPart = this.getIsThirdPart
    this.isShow = !this.$route.path.includes('sbomPackageDetail')
  },

  provide() {
    return {
      
    }
  }
});
